table.admin-actions {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
    margin-bottom: 20px;
}

table.admin-actions th, table.admin-actions td {
    text-align: left;
    padding: 10px;
    border: black 1px solid;
}

table.admin-actions tr.colored {
    background-color: #97e09a;
}

table.permissions {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
    margin-bottom: 20px;
}

table.permissions th, table.permissions td {
    text-align: left;
    padding: 10px;
    border: black 1px solid;
}

table.permissions tr.even {
    background-color: #c2c2c2;
}

table.permissions tr.odd {
    background-color: #e0e0e0;
}

.sticky-prop-wrapper {
    display: flex;
    margin: 10px;
    padding: 5px;
    width: 500px;
    border-radius: 5px;
    border: 1px solid rgb(140, 140, 174);
    background-color: white;
    justify-content: space-between;
}
